import { DragDropModule } from '@angular/cdk/drag-drop'
import { LayoutModule } from '@angular/cdk/layout'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { TextFieldModule } from '@angular/cdk/text-field'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { NgxGaugeModule } from 'ngx-gauge'
import { QuillModule } from 'ngx-quill'
import Quill from 'quill'
import 'quill-emoji'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { QuillLinkHelper } from 'src/app/shared/helpers/QuillLinkHelper'
import { RiskDomainGaugeComponent } from '../coach/components/risk-domain-gauge/risk-domain-gauge.component'
import { BrPipe } from '../coach/pipes/br.pipe'
import { LinebreakPipe } from '../coach/pipes/linebreak.pipe'
import { RecomSectionComponent } from '../dashboard/components/recom-section/recom-section.component'
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component'
import { AssessmentUpdateCtaComponent } from '../life-habits/components/assessment-update-cta/assessment-update-cta.component'
import { ProjectInfoComponent } from '../misc/components/project-info/project-info.component'
import { ObjectiveCreateCtaComponent } from '../objectives/shared/create-cta/create-cta.component'
import { PendoButtonCtaDirective } from './Pendo/directives/pendo-button-cta.directive'
import { PendoHrefCtaDirective } from './Pendo/directives/pendo-href-cta.directive'
import { PendoRouterLinkCtaDirective } from './Pendo/directives/pendo-routerlink-cta.directive'
import { PENDO_MODULE_CONTEXT } from './Pendo/pendo-injection-token'
import { ActionPlanDetailsV2Component } from './components/action-plan-details-v2/action-plan-details-v2.component'
import { ActionPlanParticipantV2Component } from './components/action-plan-participant-v2/action-plan-participant-v2.component'
import { CardObjectiveSimplifiedComponent } from './components/card-objective-simplified/card-objective-simplified.component'
import { CardObjectiveComponent } from './components/card-objective/card-objective.component'
import { ChatterComponent } from './components/chatter/chatter.component'
import { CtaContactCoachComponent } from './components/contact-coach-cta/cta-contact-coach/cta-contact-coach.component'
import { DialogEditMeetupComponent } from './components/dialog-edit-meetup/dialog-edit-meetup.component'
import { DialogExplainProfileComponent } from './components/dialog-explain-profile/dialog-explain-profile.component'
import { DialogFaqVideoAppointmentComponent } from './components/dialog-faq-video-appointment/dialog-faq-video-appointment.component'
import { DialogHistoryParticipantComponent } from './components/dialog-history-participant/dialog-history-participant.component'
import { DialogMonitoringHistoryComponent } from './components/dialog-monitoring-history/dialog-monitoring-history.component'
import { DialogObjectiveHistoryComponent } from './components/dialog-objective-history/dialog-objective-history.component'
import { DialogWrapperComponent } from './components/dialog-wrapper/dialog-wrapper.component'
import { DynamicFaqErrorsComponent } from './components/dynamic-faq-errors/dynamic-faq-errors.component'
import { FaqNotificationComponent } from './components/faq-notification/faq-notification.component'
import { GaugeDomainComponent } from './components/gauge-domain/gauge-domain.component'
import { GaugePinComponent } from './components/gauge-pin/gauge-pin.component'
import { GenericBoxComponent } from './components/generic-box/generic-box.component'
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component'
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component'
import { MotivationsProfileComponent } from './components/motivations-profile/motivations-profile.component'
import { ObjectiveStatsComponent } from './components/objective-stats/objective-stats.component'
import { RiskProfileComponent } from './components/risk-profile/risk-profile.component'
import { StreamVideoComponent } from './components/stream-video/stream-video.component'
import { TimezoneSelectableComponent } from './components/timezone-selectable/timezone-selectable.component'
import { TimezoneViewComponent } from './components/timezone-view/timezone-view.component'
import { VideoAptCamMicErrorComponent } from './components/video-apt-cam-mic-error/video-apt-cam-mic-error.component'
import { VideoCameraToolComponent } from './components/video-camera-tool/video-camera-tool.component'
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { WarningComponent } from './components/warning/warning.component'
import { ArticleCardComponent } from './content/article/article-card/article-card.component'
import { ClickOutsideElementModule } from './directives/click-outside-element/click-outside-element.module'
import { InnerHTMLDirective } from './directives/inner-html.directive'
import { IncompatibleSupportComponent } from './incompatible-support/incompatible-support.component'
import { AssessmentAnswersComponent } from './life-habits/components/assessment-answers/assessment-answers.component'
import { LinkModule } from './link/link.module'
import { ConvertDateTimeToTimezoneModule } from './pipes/convert-date-time-to-timezone/convert-date-time-to-timezone.module'
import { DaysConvertorPipe } from './pipes/days-convertor.pipe'
import { ReplacePipe } from './pipes/replace.pipe'
import { SingleFormStatePipe } from './pipes/single-form-state.pipe'
import { VersionChangeSnackComponent } from './version-change-snack/version-change-snack.component'

Quill.debug('error')
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

Quill.register(QuillLinkHelper)

@NgModule({
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatMenuModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    MatSelectModule,
    FormsModule,
    MatListModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    NgxGaugeModule,
    ProjectInfoComponent,
    MatPaginatorModule,
    TextFieldModule,
    MatRadioModule,
    DragDropModule,
    BrPipe,
    LinebreakPipe,
    MatDialogModule,
    MatSliderModule,
    GaugeDomainComponent,
    ChatterComponent,
    ActionPlanParticipantV2Component,
    DialogEditMeetupComponent,
    CardObjectiveSimplifiedComponent,
    GaugePinComponent,
    QuillModule,
    MatButtonToggleModule,
    IncompatibleSupportComponent,
    CardObjectiveComponent,
    ScrollingModule,
    LayoutModule,
    DialogFaqVideoAppointmentComponent,
    FaqNotificationComponent,
    ArticleCardComponent,
    DaysConvertorPipe,
    ConvertDateTimeToTimezoneModule,
    TimezoneSelectableComponent,
    VideoCameraToolComponent,
    RecomSectionComponent,
    WarningComponent,
    InnerHTMLDirective,
    ObjectiveStatsComponent,
    ReplacePipe,
    VideoPlayerComponent,
    CtaContactCoachComponent,
    ObjectiveCreateCtaComponent,
    AssessmentUpdateCtaComponent,
    AssessmentAnswersComponent,
  ],
  declarations: [
    ProjectInfoComponent,
    BrPipe,
    LinebreakPipe,
    GenericDialogComponent,
    ChatterComponent,
    GaugeDomainComponent,
    RiskProfileComponent,
    MotivationsProfileComponent,
    ActionPlanParticipantV2Component,
    DialogEditMeetupComponent,
    IncompatibleSupportComponent,
    StreamVideoComponent,
    ActionPlanParticipantV2Component,
    ActionPlanDetailsV2Component,
    DialogMonitoringHistoryComponent,
    CardObjectiveComponent,
    DialogObjectiveHistoryComponent,
    DialogHistoryParticipantComponent,
    CardObjectiveSimplifiedComponent,
    DialogExplainProfileComponent,
    VersionChangeSnackComponent,
    GaugePinComponent,
    RiskDomainGaugeComponent,
    DialogFaqVideoAppointmentComponent,
    FaqNotificationComponent,
    VideoAptCamMicErrorComponent,
    DynamicFaqErrorsComponent,
    DaysConvertorPipe,
    TimezoneSelectableComponent,
    VideoCameraToolComponent,
    RecomSectionComponent,
    ObjectiveStatsComponent,
    SingleFormStatePipe,
  ],
  imports: [
    MatTableModule,
    MatButtonModule,
    TextFieldModule,
    MatDialogModule,
    MatInputModule,
    NgxGaugeModule,
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSelectModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TimezoneViewComponent,
    InnerHTMLDirective,
    QuillModule.forRoot({
      modules: {
        imageDropAndPaste: {
          handler: () => {},
        },
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
          ['link'],
          [{ header: [1, 2, 3, 4, false] }],
        ],
        'emoji-toolbar': true,
        'emoji-textarea': true,
        'emoji-shortname': true,
      },
    }),
    MatButtonToggleModule,
    LayoutModule,
    ConvertDateTimeToTimezoneModule,
    MatAutocompleteModule,
    ClickOutsideElementModule,
    MatDividerModule,
    MatSidenavModule,
    WarningComponent,
    MainLayoutComponent,
    PendoButtonCtaDirective,
    PendoHrefCtaDirective,
    PendoRouterLinkCtaDirective,
    ReplacePipe,
    DialogWrapperComponent,
    LanguageSwitchComponent,
    VideoPlayerComponent,
    CtaContactCoachComponent,
    ArticleCardComponent,
    LinkModule,
    ObjectiveCreateCtaComponent,
    AssessmentUpdateCtaComponent,
    AssessmentAnswersComponent,
  ],
  providers: [
    {
      provide: PENDO_MODULE_CONTEXT,
      useValue: 'shared',
    },
    GenericBoxComponent,
  ],
})
export class SharedModule {
  constructor() {}
}
