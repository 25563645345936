import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { TModeFlow } from '../../../mode/types'
import { LucilabCommonModule } from '../../../shared/lucilab-common/lucilab-common.module'
import { PendoButtonCtaDirective } from '../../../shared/Pendo/directives/pendo-button-cta.directive'

@Component({
  selector: 'app-footer-flow',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, PendoButtonCtaDirective],
  templateUrl: './flow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterFlowComponent {
  @Input({ required: true }) mode: TModeFlow
}
