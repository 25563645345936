import { IEnvironment } from './env-type'

export const environment: IEnvironment = {
  production: false,
  env: 'dev',
  apiAuthUrl: 'https://api.dev.luciapp.ca/api/rest-auth/',
  apiUrl: 'https://api.dev.luciapp.ca/api/1.0/',
  mattermostUrl: 'https://chat.dev.luciapp.ca/api/v4/',
  mattermostWebsocketUrl: 'wss://chat.dev.luciapp.ca/api/v4/websocket',
  ga: 'G-JEEYV15M82',
  vonage_api_key: '48017211',
  featureFlags: {
    demo: true,
    lifeHabits: true,
  },
  sentry: {
    dsn: 'https://ad0f20393eb1f8b599ae96d781b35903@o4506656300662784.ingest.sentry.io/4506667529666560',
    release: 'dev',
    enable: true,
  },
  multiLanguagesAvailable: true,
}
