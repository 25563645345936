<div class='theme-dashboard' *ngIf="mode && mode.template !== 'immersive'; else immersiveBlock">
  <header class='h-20'>
    <ng-container [ngSwitch]='mode.template'>
      <app-header-default *ngSwitchCase="'default'" />
      <app-header-flow *ngSwitchCase="'flow'" [mode]='mode' />
    </ng-container>
  </header>
  <main class='bg-gray-300 min-h-[calc(100vh_-_5rem)]'>
    <router-outlet />
  </main>
  <footer>
    <ng-container [ngSwitch]='mode.template'>
      <app-footer-default *ngSwitchCase="'default'" />
      <app-footer-flow *ngSwitchCase="'flow'" [mode]='mode' />
    </ng-container>
  </footer>
</div>
<ng-template #immersiveBlock>
  <main class='theme-dashboard'>
    <router-outlet />
  </main>
</ng-template>
