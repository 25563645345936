<lib-coach-chat-bubble [bubble]='{ maxWidth: 189, backgroundColor: "#edefec", text: "Luci est fondée sur la science." }'
  position='left' [coach]='{ name: "caramos", size: "large" }'>
</lib-coach-chat-bubble>

<div class='bg-grey-1001'>
  <lib-coach-chat-bubble [bubble]='{ maxWidth: 189, backgroundColor: "white", text: "Luci est fondée sur la science." }'
    position='right' [coach]='{ name: "cedcyr", size: "large" }'>
  </lib-coach-chat-bubble>
</div>


<lib-coach-chat-bubble
  [bubble]='{ maxWidth: 226, backgroundColor: "#edefec", text: "Ensemble, améliorons votre mode de vie!" }'
  position='left' [coach]='{ name: "jbelanger", size: "large" }'>
</lib-coach-chat-bubble>

<div class='bg-grey-1001'>
  <lib-coach-chat-bubble
    [bubble]='{ maxWidth: 226, backgroundColor: "white", text: "Ensemble, améliorons votre mode de vie!" }'
    position='right' [coach]='{ name: "lpoirier", size: "large" }'>
  </lib-coach-chat-bubble>
</div>
