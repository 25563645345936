import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { delay, Subject, takeUntil } from 'rxjs'
import { ModeService } from '../mode/mode.service'
import { TMode } from '../mode/types'
import { LucilabCommonModule } from '../shared/lucilab-common/lucilab-common.module'
import { FooterDefaultComponent } from './footers/default/default.component'
import { FooterFlowComponent } from './footers/flow/flow.component'
import { HeaderDefaultComponent } from './headers/default/default.component'
import { HeaderFlowComponent } from './headers/flow/flow.component'

@Component({
  templateUrl: './luciapp.component.html',
  imports: [
    CommonModule,
    LucilabCommonModule,
    RouterOutlet,
    HeaderDefaultComponent,
    HeaderFlowComponent,
    FooterDefaultComponent,
    FooterFlowComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LuciAppComponent implements OnDestroy {
  private _mode?: TMode
  public get mode(): TMode | undefined {
    return this._mode
  }
  private set mode(value: TMode) {
    this._mode = value
    this.changeDetectorRef.markForCheck()
  }

  private readonly unsubscribe$ = new Subject<void>()

  constructor(
    private readonly modeService: ModeService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.modeService.mode$.pipe(delay(0), takeUntil(this.unsubscribe$)).subscribe((mode) => {
      this.mode = mode
    })
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
