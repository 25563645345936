import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'
import { FontModule } from '../font'

@Component({
  selector: 'lib-chat-bubble',
  standalone: true,
  imports: [CommonModule, FontModule],
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBubbleComponent {
  @HostBinding('style.max-width.px')
  @Input()
  maxWidth: number

  @Input({ required: true }) text: string

  @Input()
  backgroundColor: string = '#edefec'

  @Input()
  position: 'left' | 'right' = 'left'
}
