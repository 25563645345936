import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { LucilabCommonModule } from '../../../../shared/lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-footer-default-unregistered',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, MatIconModule],
  templateUrl: './unregistered.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterUnregisteredComponent {
  public readonly currentYear = new Date().getUTCFullYear()
}
