<div *ngIf='user$ | async as user' class='max-w-screen-desktop mx-auto flex flex-row gap-4'>
  <img src='assets/img/logo/luci.svg' alt='Luci logo' class='w-20 h-20' />
  <div class='grow flex flex-row justify-evenly'>
    <a routerLink='/dashboard/home/detail' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
      class='flex items-center py-2.5 text-grey-1000'>
      <mat-icon class='text-grey-400 mr-2.5' fontIcon="home" />
      <span i18n>Accueil</span>
    </a>

    <a routerLink='/dashboard/home/profile' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
      class='flex items-center py-2.5 text-grey-1000'>
      <mat-icon fontIcon="account_circle" class='text-grey-400 mr-2.5' />
      <span i18n>Mon bilan</span>
    </a>

    <a routerLink='/objectifs' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
      class='flex items-center py-2.5 text-grey-1000'>
      <mat-icon fontIcon="fact_check" class='text-grey-400 mr-2.5' />
      <span i18n>Mes objectifs</span>
    </a>

    <a *ngIf='!user.coach' routerLink='/dashboard/home/my-action-plan'
      routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
      class='flex items-center py-2.5 text-grey-1000'>
      <mat-icon fontIcon="emoji_people" class='text-grey-400 mr-2.5' />
      <span i18n>Mon conseiller</span>
    </a>

    <a routerLink='/podcast' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
      class='flex items-center py-2.5 text-grey-1000'>
      <mat-icon fontIcon="mic" class='text-grey-400 mr-2.5' />
      <span i18n>Balado</span>
    </a>

    <a routerLink='/categories' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
      [ngClass]="{'!text-blue-500 font-bold [&>.mat-icon]:text-blue-500': isLibraryActive}"
      class='flex items-center py-2.5 text-grey-1000'>
      <mat-icon svgIcon="lib" class='text-grey-400 mr-2.5 p-0.5' />
      <span i18n>Bibliothèque</span>
    </a>
  </div>
  <button medium tertiary icon="menu-02" iconPosition='left'>Menu</button>
</div>
