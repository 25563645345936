import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { UserService } from '../../../misc/services/user/user.service'
import { LucilabCommonModule } from '../../../shared/lucilab-common/lucilab-common.module'
import { FooterRegisteredComponent } from './registered/registered.component'
import { FooterUnregisteredComponent } from './unregistered/unregistered.component'

@Component({
  selector: 'app-footer-default',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, FooterRegisteredComponent, FooterUnregisteredComponent],
  templateUrl: './default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterDefaultComponent {
  public readonly user$ = this.userService.user$

  constructor(private readonly userService: UserService) {}
}
