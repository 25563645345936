import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { TMode } from './types'

@Injectable({
  providedIn: 'root',
})
export class ModeService {
  public readonly mode$ = new Subject<TMode>()
}
