import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { CoachAvatarComponent, CoachSize } from '../../coach-avatar/coach-avatar.component'
import { UikitModule } from '../../uikit.module'

@Component({
  selector: 'lib-demo-coach-avatar',
  standalone: true,
  imports: [CommonModule, CoachAvatarComponent, UikitModule],
  templateUrl: './coach-avatar.component.html',
})
export class DemoCoachAvatarComponent {
  public readonly coaches = [
    'caramos',
    'cedcyr',
    'lpoirier',
    'mvipond',
    'mlagace',
    'ndittrich',
    'jbelanger',
    'sbonilladuque',
    'sstaco',
  ]
  public readonly sizes: CoachSize[] = ['x-large', 'large', 'medium', 'small', 'x-small']
}
