import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { UserService } from '../../../misc/services/user/user.service'
import { LucilabCommonModule } from '../../../shared/lucilab-common/lucilab-common.module'
import { HeaderRegisteredComponent } from './registered/registered.component'
import { HeaderUnregisteredComponent } from './unregistered/unregistered.component'

@Component({
  selector: 'app-header-default',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, HeaderRegisteredComponent, HeaderUnregisteredComponent],
  templateUrl: './default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDefaultComponent {
  public readonly user$ = this.userService.user$

  constructor(private readonly userService: UserService) {}
}
