/// <reference path='../../../../../node_modules/@types/pendo-io-browser/index.d.ts' />

import { inject, Injectable, LOCALE_ID } from '@angular/core'
import { combineLatest, filter, map, Subscription } from 'rxjs'
import { ActionPlanService } from '../dashboard/services/action-plan/action-plan.service'
import { ConfigService } from '../misc/config/config.service'
import { UserService } from '../misc/services/user/user.service'
import { User } from '../shared/interfaces/user'
import { IPendoDatas } from './definitions'

@Injectable({ providedIn: 'root' })
export class PendoService {
  private readonly _pendo: pendo.Pendo = window['pendo']
  private readonly _subscriptions = new Subscription()
  private readonly _language = inject(LOCALE_ID)

  private readonly configService = inject(ConfigService)
  private readonly userService = inject(UserService)
  private readonly actionPlanService = inject(ActionPlanService)

  public initialize(): void {
    this._subscriptions.add(
      combineLatest([this.userService._user, this.configService.config$])
        .pipe(
          filter(
            ([user, config]) =>
              !!user && !!config && ((user.anonymous && !config.pendo.deactivate_for_anon_users) || !user.anonymous),
          ),
          map(([user]) => this.computeDatas(user)),
        )
        .subscribe((pendoDatas) => {
          if (this._pendo.isReady?.()) {
            this._pendo.updateOptions(pendoDatas)
          } else {
            this._pendo.initialize(pendoDatas)
          }
        }),
    )
  }

  public dispose(): void {
    return this._subscriptions.unsubscribe()
  }

  private computeDatas(user: User): IPendoDatas {
    const getRoleFn = () => {
      if (user.anonymous) return 'anonyme'
      if (user.is_coach) return 'conseiller'
      return 'participant'
    }

    const countObjectivesFn = (domainFilter: string): number => {
      const actionPlan = this.actionPlanService.actionPlan?.[0]

      return actionPlan?.objectives.filter(({ domain }) => domain === domainFilter).length ?? 0
    }

    return {
      account: {
        id: user.account?.id ?? 'ANONYMOUS',
        name: user.account?.name ?? 'ANONYMOUS',
      },
      visitor: {
        id: user.uuid,
        user_language: this._language,
        role: getRoleFn(),
        advisor_id: user.coach?.uuid,
        physical_status: user.physical_active ? 'active' : user.domain_physical,
        nutrition_status: user.nutrition_active ? 'active' : user.domain_nutrition,
        intellectual_status: user.intellectual_active ? 'active' : user.domain_intellectual,
        assigned_resources_count: user.articles_assigned_count,
        study_group: user.study_group,
        environment: window.location.hostname,
        objectives_intellectual_count: countObjectivesFn('intellectual'),
        objectives_nutrition_count: countObjectivesFn('nutrition'),
        objectives_physical_count: countObjectivesFn('physical'),
      },
    }
  }
}
