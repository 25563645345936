<div class='theme-promo bg-[#2E4F57] px-4 py-20'>
  <div
    class='flex flex-col gap-14 justify-center items-center desktop:flex-row desktop:max-w-screen-desktop desktop:mx-auto desktop:items-start'>
    <!-- ********* -->
    <!-- LOGO LUCI -->
    <!-- ********* -->
    <div>
      <a routerLink='/dashboard/home/detail' i18n-href='unregistered-footer-href-logo'>
        <img src='assets/img/logo/luci_white_orange.svg' alt='logo'>
      </a>
    </div>
    <!-- ********* -->
    <!-- END LOGO LUCI -->
    <!-- ********* -->

    <!-- ********* -->
    <!-- LINK TO PAGE IN APP -->
    <!-- ********* -->

    <div class='flex flex-wrap gap-6 md:gap-14'>
      <div class='flex flex-col gap-6'>
        <span title-3 bold class='!text-white whitespace-nowrap' i18n='about'>À propos</span>
        <div class='flex flex-col gap-4'>
          <a label-2 routerLink="/a-propos/mission" class='!text-white !no-underline'>Mission</a>
          <a label-2 routerLink="/a-propos/science" class='!text-white !no-underline'>Science</a>
          <a label-2 routerLink="/a-propos/equipe" class='!text-white !no-underline' i18n='menu.about.team'>Équipe</a>
        </div>
      </div>
      <div class='flex flex-col gap-6'>
        <span title-3 bold class='!text-white whitespace-nowrap' i18n='content'>Contenus</span>
        <div class='flex flex-col gap-4'>
          <a label-2 routerLink="/categories" class='!text-white !no-underline' i18n='library'>Bibliothèque</a>
          <a label-2 routerLink="/podcast" class='!text-white !no-underline' i18n='podcast'>Balado</a>
        </div>
      </div>
      <div class='flex flex-col gap-6'>
        <span title-3 bold class='!text-white whitespace-nowrap' i18n='about'>Nous joindre</span>
        <div class='flex flex-col gap-4'>
          <a label-2 routerLink="/a-propos/contact" class='!text-white !no-underline'>Contact</a>
          <a label-2 routerLink="/faq" class='!text-white !no-underline'>FAQ</a>
        </div>
      </div>
    </div>
    <!-- ********* -->
    <!-- END LINK TO PAGE IN APP -->
    <!-- ********* -->

    <hr class='w-60 h-[1px] tablet:w-72 bg-white desktop:w-[1px] desktop:h-[182px]'>

    <!-- ********* -->
    <!-- NEWSLETTER AND MEDIA LINK -->
    <!-- ********* -->
    <div class='flex flex-col gap-10 items-center tablet:items-start'>
      <div class='w-full flex flex-col items-center tablet:w-fit'>
        <p title-3 bold class='!text-white !mb-3 !text-center tablet:self-start' i18n='subscribe-news-letter'>
          Inscrivez-vous à notre infolettre</p>
        <form *ngIf="newsLetterFormStatus !== 'successful'; else formSumbittedSuccessfully"
          class='flex flex-col items-center gap-5 tablet:mb-0 tablet:flex-row tablet:gap-3 tablet:items-start'
          [formGroup]='newsLetterForm' (ngSubmit)="submitToNewsLetter()">
          <div>
            <mat-form-field appearance="outline" color='primary' class='w-full'>
              <input matInput formControlName='email' placeholder="Adresse courriel" i18n-placeholder='email-address'>
              <mat-error *ngIf="newsLetterFormStatus === 'submitted' && newsLetterForm.controls.email.invalid"
                i18n='invalid-email'>
                Veuillez entrer une adresse courriel valide
              </mat-error>
            </mat-form-field>
            <div class='flex gap-1 flex-col'>
              <div class='flex gap-2'>
                <input type='checkbox' id='acceptedTerm' name='acceptedTerm' formControlName='acceptedTerm' class='w-5'>
                <label label-3 for='acceptedTerm' class='text-white' i18n='consent-news-letter'>
                  Je consens à recevoir les infolettres de Luci
                </label>
              </div>
              <p caption-5 *ngIf="newsLetterForm.controls.acceptedTerm.invalid && newsLetterFormStatus === 'submitted'"
                class='!text-red-500 !px-4' i18n='do-consent'>Vous devez consentir à recevoir les infolettres</p>
            </div>
          </div>
          <button primary rounded class='w-[142px]' type='submit' [disabled]='newsLetterFormStatus === "submitting"'
            pendoClass='subscribe-to-news-letter' i18n='subscribe'>M'inscrire</button>
        </form>
      </div>

      <!-- ********* -->
      <!-- MEDIA LINK -->
      <!-- ********* -->
      <div class='flex justify-center gap-6 items-center'>
        <a class='w-fit' href='https://www.facebook.com/lucilab.ca' target='_blank'>
          <img class='h-7' src='assets/img/facebook.svg' alt='Facebook'>
        </a>
        <a class='w-fit' href='https://www.youtube.com/channel/UCTMq6ffXKnSTLKtOt6nuP8w' target='_blank'>
          <img class='h-9' src='assets/img/youtube-white.svg' alt='Youtube'>
        </a>
        <a class='w-fit' href='https://www.instagram.com/lucilab.ca/' target='_blank'>
          <img class='h-8' src='assets/img/home_page/icon_instagram.svg' alt='Instagram'>
        </a>
        <a class='w-fit' href='https://www.linkedin.com/company/lucilab' target='_blank'>
          <img class='h-7' src='assets/img/linkedin.svg' alt='linkedin'>
        </a>
      </div>
    </div>
    <!-- ********* -->
    <!-- END NEWSLETTER AND MEDIA LINK -->
    <!-- ********* -->
  </div>

  <div class='mt-12 flex flex-row flex-wrap items-end justify-center divide-x-2 gap-4'>
    <span class='label-4 desktop:label-3 text-white' i18n='unregistered-footer-copyright'>
      © {{now | date: 'yyyy'}} - Lucilab Inc. Tous droits réservés
    </span>
    <a class='label-4 desktop:label-3 !text-white !pl-4 !no-underline'
      routerLink="/a-propos" i18n='@@footer.about'>À propos</a>
      <a class='label-4 desktop:label-3 !text-white !pl-4 !no-underline'
      routerLink="/avis-juridiques" i18n='@@footer.legal-notice'>Avis juridiques</a>
  </div>
</div>

<ng-template #formSumbittedSuccessfully>
  <div class='flex gap-2 self-start'>
    <img src='./assets/img/green-checkmark-rounded.png' class='w-[21px] h-[21px]' alt=''>
    <p label-2 bold class='!text-green-800' i18n='thank-you-news-letter'>Inscription réussie !</p>
  </div>
</ng-template>
