import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { CoachChatBubbleComponent } from '../../coach-chat-bubble/coach-chat-bubble.component'
import { FontModule } from '../../font'

@Component({
  selector: 'lib-demo-coach-chat-bubble',
  standalone: true,
  imports: [CommonModule, CoachChatBubbleComponent, FontModule],
  templateUrl: './coach-chat-bubble.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoCoachChatBubbleComponent {}
