import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

export type CoachSize = 'x-small' | 'small' | 'medium' | 'large' | 'x-large'

@Component({
  selector: 'lib-coach-avatar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './coach-avatar.component.html',
  styleUrls: ['./coach-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachAvatarComponent {
  @Input({ required: true }) coach: string
  @Input() size: CoachSize = 'medium'
}
