/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core'
import { ScrollToTopComponent } from '../button/scroll-to-top/scroll-to-top.component'
import { DemoAccordionComponent } from './accordion/accordion.component'
import { DemoBannerComponent } from './banner/banner.component'
import { ButtonComponent } from './button/button.component'
import { DemoCoachAvatarComponent } from './coach-avatar/coach-avatar.component'
import { DemoCoachChatBubbleComponent } from './coach-chat-bubble/coach-chat-bubble.component'
import { FontComponent } from './font/font.component'
import { DemoInputComponent } from './input/input.component'
import { DemoSelectComponent } from './select/select.component'
@Component({
  selector: 'uikit-demo',
  templateUrl: './demo.component.html',
  standalone: true,
  imports: [
    ButtonComponent,
    FontComponent,
    DemoInputComponent,
    DemoAccordionComponent,
    DemoBannerComponent,
    ScrollToTopComponent,
    DemoSelectComponent,
    DemoCoachAvatarComponent,
    DemoCoachChatBubbleComponent,
  ],
})
export class DemoComponent {}
