<div class='fixed z-50 h-18 bottom-0 w-full bg-white shadow-[8px_-4px_24px_0_#0000001F]'>
  <div class='h-full max-w-screen-desktop mx-auto px-4 grid grid-cols-[auto_1fr_auto] gap-4 items-center
    justify-items-center'>
    <div>
      <button *ngIf='mode.footer.left && !mode.footer.left.hidden' tertiary rounded medium icon='arrow-left'
        iconPosition='icon-only' (click)='mode.footer.left.onClick()' [pendoClass]="'mode-flow-footer-button-left'"
        [disabled]='mode.footer.left.disabled'></button>
    </div>

    <div>
      <ng-container *ngIf='mode.footer.middle && !mode.footer.middle.hidden'>
        <button *ngIf='mode.footer.middle.mode === "button"' primary rounded medium [icon]='mode.footer.middle.icon'
          iconPosition="right" (click)='mode.footer.middle.onClick()' [pendoClass]="'mode-flow-footer-button-middle'"
          [disabled]='mode.footer.middle.disabled' i18n='@@mode.flow.footer.next'>Suivant</button>

        <div *ngIf='mode.footer.middle.mode === "state"' class='flex flex-row gap-2 items-center'
          [ngClass]='mode.footer.middle.style'>
          <ng-container *ngIf='mode.footer.middle.style === "warning"'>
            <img src='/assets/img/modes/flow/warning.svg' />
            <span caption-4 class='text-orange-1500'>{{mode.footer.middle.text}}</span>
          </ng-container>
          <ng-container *ngIf='mode.footer.middle.style === "error"'>
            <img src='/assets/img/modes/flow/error.svg' />
            <span caption-4 class='text-red-1500'>{{mode.footer.middle.text}}</span>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div>
      <button *ngIf='mode.footer.right && !mode.footer.right.hidden' primary rounded medium icon='arrow-right'
        iconPosition="right" (click)='mode.footer.right.onClick()' [pendoClass]="'mode-flow-footer-button-right'"
        [disabled]='mode.footer.right.disabled' i18n='@@mode.flow.footer.next'>Suivant</button>
    </div>
  </div>
</div>
