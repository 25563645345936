import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute } from '@angular/router'
import { map, Subject, tap } from 'rxjs'
import { UserService } from '../../../../misc/services/user/user.service'
import { LucilabCommonModule } from '../../../../shared/lucilab-common/lucilab-common.module'

@Component({
  selector: 'app-header-default-registered',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, MatIconModule],
  templateUrl: './registered.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderRegisteredComponent implements OnDestroy {
  public isLibraryActive = false

  public readonly user$ = this.userService.user$.pipe(tap(console.log))

  private readonly unsubscribe$ = new Subject<void>()

  constructor(
    private readonly userService: UserService,
    activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.url
      .pipe(
        map((segments) => {
          const libraryRoute = ['articles', 'themes']
          return libraryRoute.includes(segments[0]?.path)
        }),
      )
      .subscribe((isLibraryActive) => {
        this.isLibraryActive = isLibraryActive
      })
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
