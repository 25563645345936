import { HttpClient, HttpContext } from '@angular/common/http'
import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { environment } from 'projects/environments/environment'
import { map, switchMap, tap } from 'rxjs/operators'
import { LoginRes } from 'src/app/interfaces/login-res'
import { UserService } from 'src/app/misc/services/user/user.service'
import { TSignupPayload } from 'src/app/shared/interfaces/register'
import { User } from 'src/app/shared/interfaces/user'
import { IS_SNACKBAR_SHOWED } from '../../shared/http-contexts/show-snackbar.context'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly url: string = environment.apiAuthUrl

  constructor(
    private http: HttpClient,
    private userServ: UserService,
    @Inject(LOCALE_ID) private local_id: string,
  ) {}

  login(payload: { email: string; password: string; public: boolean }) {
    return this.http.post<User>(`${environment.apiAuthUrl}login/`, payload).pipe(
      tap(({ key }: any) => {
        if (payload.public) {
          localStorage.removeItem('token')
          sessionStorage.setItem('token', key)
        } else {
          sessionStorage.removeItem('token')
          localStorage.setItem('token', key)
        }
      }),
      switchMap(() => this.userServ.getUser()),
    )
  }

  register(registerForm: TSignupPayload) {
    return this.http.post<LoginRes>(`${environment.apiAuthUrl}registration/`, registerForm)
  }

  requiredBirthDate() {
    return this.http.get(`${environment.apiUrl}auth-requirements/`)
  }

  resetPassword(form) {
    return this.http.post(`${environment.apiAuthUrl}password/reset/`, form)
  }

  authTokenStatus(uid, token) {
    return this.http
      .get(`${environment.apiAuthUrl}password/reset/token_status/?uid=${uid}&token=${token}`)
      .pipe(map((res) => res['status']))
  }

  setNewPassword(form) {
    return this.http.post(`${environment.apiAuthUrl}password/reset/confirm_login/`, form).pipe(
      tap((res) => {
        if (form['keep']) {
          localStorage.setItem('token', res['key'])
        } else {
          sessionStorage.setItem('token', res['key'])
        }
      }),
    )
  }

  changePassword(form) {
    return this.http.post(`${environment.apiAuthUrl}password/change/`, form)
  }

  getAccounts() {
    return this.http.get(`${environment.apiUrl}accounts/`, {
      params: { ['lang']: this.local_id },
    })
  }

  confirmEmail(uid: string, token: string) {
    return this.http
      .post(
        `${this.url}email-status/confirm/`,
        { uid, token },
        { context: new HttpContext().set(IS_SNACKBAR_SHOWED, false) },
      )
      .pipe(
        tap(() => {
          if (!this.userServ.user.anonymous) {
            this.userServ.user = { ...this.userServ.user, email_confirmed: true }
          }
        }),
      )
  }

  resendEmailConfirmationLink(email: string = null) {
    const url = email !== null ? `${this.url}email-status/request-email/` : `${this.url}email-status/`

    return this.http.post(url, email ? { email } : null)
  }

  clearStorage(): void {
    sessionStorage.clear()
    localStorage.clear()
  }
}
