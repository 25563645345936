import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { UserService } from '../services/user/user.service'

export const ValidateEmailGuardFn: CanActivateFn = () => {
  const userServ = inject(UserService)
  const router = inject(Router)

  return userServ.user$.pipe(map((user) => !user.email_confirmed || router.createUrlTree(['/dashboard/home/detail'])))
}
