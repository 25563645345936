import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ChatBubbleComponent } from '../chat-bubble/chat-bubble.component'
import { CoachAvatarComponent, CoachSize } from '../coach-avatar/coach-avatar.component'
@Component({
  selector: 'lib-coach-chat-bubble',
  standalone: true,
  imports: [CommonModule, ChatBubbleComponent, CoachAvatarComponent],
  templateUrl: './coach-chat-bubble.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoachChatBubbleComponent {
  @Input({ required: true }) coach: { name: string; size: CoachSize }
  @Input({ required: true }) bubble: { maxWidth: number; backgroundColor: string; text: string }
  @Input() position: 'left' | 'right' = 'left'
}
